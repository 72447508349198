import React from 'react';
import './Footer.scss';
import partner_1 from '../../assets/datalogic.png';
import partner_2 from '../../assets/honeywell.png';
import partner_3 from '../../assets/zebra.png';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const current_year = new Date().getFullYear();

const Footer = () => {
    return (
        <div className='container-fluid footer'> 
            <div className="container pt-5">
                <div className="row">
                    <div className="col-md-6">
                        <h2>Trusted Partners</h2>
                        <div className="row pt-4">
                            <div className="col-md-4 my-2">
                                <img src={partner_2} alt='Honeywell Partner' className='img-fluid top-partner' width='437' height='305' />
                            </div>
                            <div className="col-md-4 my-2">
                                <img src={partner_3} alt='Zebra Business Partner' className='img-fluid top-partner' width='251' height='163' />
                            </div>
                            <div className="col-md-6 pt-4">
                                <img src={partner_1} alt="Datalogic Partner" className='img-fluid tpo-partner' width='1048' height='187' />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <h5>Our Offices:</h5>
                        <ul className='list-unstyled'>
                            <li>Calderwood Lifestyle Estate</li>
                            <li>The Stewards</li>
                            <li>Benoni</li>
                            <li>1501</li>
                        </ul>
                        <h5>Contact Us:</h5>
                        <ul className='list-unstyled'>
                            <li><a className='text-decoration-none text-white' href="mailto:sales@balungisisolutions.co.za">sales@balungisisolutions.co.za</a></li>
                            <li><a className='text-decoration-none text-white' href="mailto:support@balungisisolutions.co.za">support@balungisisolutions.co.za</a></li>
                            <li><a className='text-decoration-none text-white' href="tel:072 135 1055">072 135 1055</a></li>
                            <li><a className='text-decoration-none text-white' href="tel:081 380 1598">081 380 1598</a></li>
                        </ul>
                    </div>
                    <div className="col-md-2">
                        <ul className='list-unstyled'>
                            <li><Link className='text-decoration-none text-white' to='/'>Home</Link></li>
                            <li><Link className='text-decoration-none text-white' to='/about'>About</Link></li>
                            <li><HashLink className='text-decoration-none text-white' to='/#services'>Services</HashLink></li>
                            <li><Link className='text-decoration-none text-white' to='/contact'>Contact Us</Link></li>
                            <li><Link className='text-decoration-none text-white' to='/products/scanner'>Barcode Scanners</Link></li>
                            <li><Link className='text-decoration-none text-white' to='/products/printer'>Barcode Printers</Link></li>
                            <li><Link className='text-decoration-none text-white' to='/products/label'>Barcode Labels</Link></li>
                            <li><Link className='text-decoration-none text-white' to='/products/mobile computer'>Mobile Computers</Link></li>
                        </ul>
                    </div>
                    <div className="col-12">
                        <hr />
                    </div>
                    <div className="col-12 text-center pb-3">
                        Balungisi Solutions © {current_year}
                    </div>
                </div>
            </div>           
        </div>
    );
};

export default Footer;