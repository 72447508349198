import { React, useEffect } from 'react';
import Cta from '../../components/cta/Cta';
import Footer from '../../components/footer/Footer';
import Hero from '../../components/hero/Hero';
import TopNavbar from '../../components/navbar/TopNavbar';
import './About.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import Kapandula from '../../assets/Kapandula.jpg';
import Sifiso_1 from '../../assets/Sifiso_1.jpg';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const About = () => {

    const pathname = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className='about'>
            <Helmet>
                <title>About Us | Balungisi Solutions | Innovators in Warehouse Solutions</title>
                <meta
                    name="description"
                    content="Our Directors have over 10 years of experience between them and are able to provide solutions to many issues in warehouse supply chain management and RFID solutions."
                />
                <link rel="canonical" href="/about" />
                <meta 
                    property="og:title" 
                    content="About Us | Balungisi Solutions | Innovators in Warehouse Solutions" 
                />
                <meta 
                    property="og:description" 
                    content="Our Directors have over 10 years of experience between them and are able to provide solutions to many issues in warehouse supply chain management and RFID solutions." 
                />
                <meta 
                    property="og:url" 
                    content="https://balungisisolutions.co.za/about" 
                />
            </Helmet>
            <TopNavbar />
            <Hero page='about-page' title='Innovators in Warehouse Solutions' subtitle='Customized technology to meet your business needs' />
            <div className="container py-5">
                <div className="row">
                    <div className="col-md-6">
                        <h3>At Balungisi Solutions, Our goal is to minimise any downtime our clients may experience due to unforeseen circumstances. We are experts in warehouse barcoding and scanning technology.</h3>
                    </div>
                    <div className="col-md-6">
                        <p>The way we do this is through our focus on customer service and competitive pricing. We understand that not all customers needs are the same hence we offer custom solutions to fit a customers needs and pocket.</p>
                        <p>Our Directors have over 10 years of experience between them and are able to provide solutions to many issues in warehouse supply chain management and RFID solutions.</p>
                        <p>We are a full-service solutions company, specialising in the technology, systems, and the supporting media and hardware required to identify, manage, and track all stock, operations, and transactions.</p>
                    </div>
                </div>
            </div>
            <Cta />
            <div className="container">
                <div className="row team my-5">
                    <h2 className='identity-h1'>Identity</h2>
                    <p className='mt-3 text-center'>Balungisi was founded on the 1st October 2019. It is a 100% owned <span className='text-primary fw-bold'>BBBEE</span> company. We are based on the East Rand in Gauteng. The directors are:</p>
                    <div className="col-md-3">
                        <img src={Kapandula} className="card-img-top img-fluid my-3" alt="Kapandula Phiri" width='236' height='239' />
                        <h5>Kapandula Phiri</h5>
                        <p>Managing Director</p>
                        <a href='mailto:kapip@balungisisolutions.co.za'><FontAwesomeIcon className='icon' icon={faEnvelope} /></a>
                        <a href='tel:081 380 1598'><FontAwesomeIcon className='icon' icon={faPhone} /></a>
                    </div>
                    <div className="col-md-3">
                        <img src={Sifiso_1} className="card-img-top img-fluid my-3" alt="Sifiso Ndlovu" width='236' height='239' />
                        <h5 className="card-title">Sifiso Ndlovu</h5>
                        <p>CEO</p>
                        <a href='mailto:sifison@balungisisolutions.co.za'><FontAwesomeIcon className='icon' icon={faEnvelope} /></a>
                        <a href='tel:072 135 1055'><FontAwesomeIcon className='icon' icon={faPhone} /></a>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default About;