import { React, useState, useCallback, useEffect } from 'react';
import './Product.scss';
import Footer from '../../components/footer/Footer';
import TopNavbar from '../../components/navbar/TopNavbar';
import Sidekick from '../../components/sidekick/Sidekick';
import { products } from '../../data/products';
import { Link, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightLong } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet-async';

const Product = () => {

    const param = useParams();
    const productParam = param.product.replaceAll("-", " ");
    const categoryParam = param.category;
    const { pathname } = useLocation();
    const [ singleProduct ] = useState(products.filter(item => {
        return item.name === productParam
    }));
    const [ title, setTitle ] = useState();
    const [ subtitle, setSubtitle ] = useState();

    const filterItem = useCallback(() => {
        setTitle(productParam);
        setSubtitle(`Products / ${categoryParam.charAt(0).toUpperCase() + categoryParam.slice(1)}s`);
       
      }, [productParam, categoryParam])

    useEffect(() => {
        window.scrollTo(0, 0);

        filterItem();
    }, [filterItem, pathname])

    return (
        <div className='product'>
             <Helmet>
                <title>{`${productParam} | Balungisi Solutions | Warehouse Technology and Solutions`}</title>
                <link rel="canonical" href={`/product/${categoryParam}/${param.product}`} />
                <meta 
                    property="og:url" 
                    content={`https://balungisisolutions.co.za/product/${categoryParam}/${param.product}`}
                />
                <meta
                    name="description"
                    content={`Trusted DataLogic, Honeywell and Zebra Business partners, we provide advanced scannning, printer, labelling and mobile computer products and hardware.`}
                />
                <meta 
                    property="og:title" 
                    content={`${productParam} | Balungisi Solutions | Warehouse Technology and Solutions`} 
                />
                <meta 
                    property="og:description" 
                    content={`Trusted DataLogic, Honeywell and Zebra Business partners, we provide advanced scannning, printer, labelling and mobile computer products and hardware.`}
                />
            </Helmet>
            <TopNavbar />
            <Sidekick title={title} subtitle={subtitle} page='generic' />
            <div className="container">
                <div className="row py-5">
                    {singleProduct.map(item => {
                        return (
                            <div key={item.id} className="col-md-8">
                                <div className="image-container">
                                    <img src={item.img} alt={item.name} className='img-fluid product-image' width='534' height='472' />
                                </div>
                                <p>{item.bio}</p>
                                <p>{item.p_1}</p>
                                <p>{item.p_2}</p>
                                <p>{item.p_3}</p>
                            </div>
                        )
                    })}
                    <div className="col-md-4 mt-5">
                        <h6 className='mb-4'>PRODUCT CATALOGUE</h6>
                        <ul>
                            <li>
                                <Link to='/products/scanner'><FontAwesomeIcon className='icon' icon={faRightLong} /> Barcode Scanners</Link>
                            </li>
                            <hr />
                            <li>
                                <Link to='/products/printer'><FontAwesomeIcon className='icon' icon={faRightLong} /> Barcode Printers</Link>
                            </li>
                            <hr />
                            <li>
                                <Link to='/products/label'><FontAwesomeIcon className='icon' icon={faRightLong} /> Barcode Labels</Link>
                            </li>
                            <hr />
                            <li>
                                <Link to='/products/mobile computer'><FontAwesomeIcon className='icon' icon={faRightLong} /> Mobile Computers</Link>
                            </li>
                            <hr />
                        </ul>
                        <div className='bg-primary text-white text-center p-4 rounded-0'>
                            <h4>Want to know more about our product line?</h4>
                            <Link to='/contact'><button className='btn btn-light text-primary rounded-0 my-3'>Get in touch</button></Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Product;