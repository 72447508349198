import img_0 from '../assets/motorola mc9200.png';
import img_1 from '../assets/ZT230 Barcode Label Printer.png';
import img_2 from '../assets/LI4278 Handheld Scanner.png';
import img_3 from '../assets/Thermal Roll Labels.png';
import img_4 from '../assets/ZT410 Print Head.png';
import img_5 from '../assets/Zebra GX420t Thermal Printer.png';
import img_6 from '../assets/CK65 Handheld Scanners.png';
import img_7 from '../assets/Eda51 Mobile Scanners.png';
import img_8 from '../assets/PM42 Printer.png';
import img_9 from '../assets/PM43 Printer.png';
import img_10 from '../assets/ZD220 Thermal Transfer Printer.png';
import img_11 from '../assets/ZQ600 Series Mobile Printers.png';
import img_12 from '../assets/Black Barcode Resin Ribbons.png';
import img_13 from '../assets/ZT400 Series Industrial Printer.png';
import img_14 from '../assets/Skorpio X5.png';

export const products = [
    {
        id: 1,
        category: 'mobile computer',
        name: 'MC9200 Mobile Computer',
        brief: 'The MC9200 once again raises the bar on rugged mobile computing and device flexibility, with your choice of three operating systems',
        p_1: 'The MC9200 once again raises the bar on rugged mobile computing and device flexibility, with your choice of three operating systems, the ability to switch between operating systems, seven of our most advanced scan engines, six interchangeable keypads and plenty of power to support today’s highly-graphical applications.',
        p_2: 'And if you already own MC9000 Series mobile computers, you can use the same accessories with the MC9200, demonstrating our commitment to helping our customers upgrade as cost-effectively as possible to the newest technology platform.',
        img: img_0
    },
    {
        id: 2,
        category: 'printer',
        name: 'ZT230 Barcode Label Printer',
        brief: 'Zebra incorporated extensive customer feedback to create the ZT200 series printers',
        p_1: 'Zebra incorporated extensive customer feedback to create the ZT200 series printers, which feature space-saving design, effortless setup, intuitive user operation, and ease of service and maintenance.',
        p_2: 'Whether you are adopting barcode technology for the first time or upgrading existing printer models, the ZT200 series is the right choice for a variety of labeling applications. This innovative printer provides many user benefits The ZT200 series offers a streamlined design and smaller footprint.',
        img: img_1
    },
    {
        id: 3,
        category: 'scanner',
        name: 'LI4278 Handheld Scanner',
        brief: 'Built for all day and everyday use, the LI4278 offers cordless freedom with Bluetooth compatibility',
        p_1: 'Built for all day and everyday use, the LI4278 offers cordless freedom with Bluetooth compatibility. It also offers better encryption for improved security and better overall wireless performance.',
        p_2: 'The LI4278 is backward compatible with its key accessory — the cradle — which works with its cousins, the LS4278 and DS6878. Superior battery power management delivers the largest number of scans per battery charge to support scan intensive applications. You can use it in dusty and wet environments and it can survive a 6 foot/1.8 m drop.',
        img: img_2
    },
    {
        id: 4,
        category: 'label',
        name: 'Thermal Roll Labels',
        brief: 'Thermotransfer sticker SEMI-GLOSS (Coat) is a paper label which printing is carried out by carbon ribbon',
        p_1: 'Thermotransfer sticker SEMI-GLOSS (Coat) is a paper label which printing is carried out by carbon ribbon. Label printing is performed with a special thermo transfer printer. SEMI-GLOSS (Cout) stickers are used for long-term storage products, as they do not have a bleaching effect, they are resistant to temperature changes and exterior environment effects.',
        p_2: 'High quality paper, intended for full-colour printing with high contrast and resolution. The advantage of polypropylene labels is the adaptability to diverse production conditions, which allows wide range of labeling tasks.',
        img: img_3
    },
    {
        id: 5,
        category: 'printer',
        name: 'ZT410 Print Head',
        brief: 'Our genuine Zebra ZT410 replacement printhead delivers quality, reliability and performance. The Zebra ZT410 printhead is easy',
        p_1: 'Our genuine Zebra ZT410 replacement printhead delivers quality, reliability and performance. The Zebra ZT410 printhead is easy to install and made to exacting engineering standards for continued reliability and performance from your printer.',
        p_2: 'Zebra replacement thermal printheads are made by Zebra, for Zebra printers, to the highest standards. Using genuine Zebra replacement thermal printheads will give optimum printer performance. By using genuine Zebra replacement printheads, you can be sure of consistent performance.',
        img: img_4
    },
    {
        id: 6,
        category: 'printer',
        name: 'Zebra GX420t Thermal Printer',
        brief: 'The direct thermal/thermal transfer Zebra GX420t label printer has the easiest ribbon-loading system around.',
        p_1: 'The direct thermal/thermal transfer Zebra GX420t label printer has the easiest ribbon-loading system around. Like the GX420d, it produces 203 dpi print resolution at speeds up to 6 inches per second, and like all GX models, its well-suited to a variety of applications and industries thanks to a wide choice of options.',
        p_2: 'The best-in-class enhanced GX420t thermal transfer printer offers the widest range of features, and fast 6 inches per second print speed to meet all your low- to medium-volume printing requirements. The GX420t has a sleek and compact design, boasts the easiest ribbon loading system on the market, and supports a variety of applications and industries.',
        p_3: 'The GX420t comes standard with EPL and ZPL programming languages and triple connectivity for easy integration into existing systems. For additional flexibility, 10/100 internal Ethernet and Wireless, 802.11 b/g or Bluetooth, options are also available. Well-suited to any industry and with options such as an adjustable media sensor, cutter and peeler, the reliable GX420t desktop printer is a smart investment that’s always a perfect fit.',
        img: img_5
    },
    {
        id: 7,
        category: 'mobile computer',
        name: 'CK65 Handheld Scanners',
        brief: 'The CK65 mobile computer boosts supply chain productivity by accelerating and error-proofing work in distribution',
        p_1: 'The CK65 mobile computer boosts supply chain productivity by accelerating and error-proofing work in distribution centers and manufacturing. With the longest supported lifecycle available, up to 28-hour battery life, and best-in-class ruggedness, the CK65 maximizes productive uptime, while providing an easy path for users migrating from legacy Windows operating systems to the Android platform.',
        p_2: 'Built on the Mobility Edge platform, the CK65 has received a prestigious STIG (Security Technical Implementation Guide) designation from the Defense Information Systems Agency (DISA) for its security capabilities. This recognition from DISA and the U.S. Department of Defense acknowledges the advanced security applications and tools the CK65 encompasses, ensuring your data is kept safe and secure throughout the life of the device.',
        p_3: 'Enhanced 1D/2D scanning/data capture for short, mid, or long range workflows, as well as direct part marking scan capabilities.',
        img: img_6
    },
    {
        id: 8,
        category: 'mobile computer',
        name: 'Eda51 Mobile Scanners',
        brief: 'The ScanPal™ EDA51 mobile computer, built on Android™, features the perfect combination of integrated scanning, powerful',
        p_1: 'The ScanPal™ EDA51 mobile computer, built on Android™, features the perfect combination of integrated scanning, powerful processing, intuitive touchscreen interface. It is ideal for small to medium-sized businesses to increase productivity in light duty retail, DSD, pickup and delivery, and field service applications.',
        p_2: 'The ScanPal EDA51 can be upgraded through three generations of Android (up to Android Q) and is backward-compatible with ScanPal EDA50 accessories, batteries, and chargers for extended lifecycle, better ROI, and a lower total cost of ownership.',
        p_3: 'Built for all-day use, with a removable 4,000 mAh lithium ion battery pack that lasts 12 hours without adding weight or bulk.',
        img: img_7
    },
    {
        id: 9,
        category: 'printer',
        name: 'PM42 Printer',
        brief: 'The Honeywell PM42 industrial label printer is designed for reliability and uptime. With a durable construction and an intuitive',
        p_1: 'The Honeywell PM42 industrial label printer is designed for reliability and uptime. With a durable construction and an intuitive, user-friendly interface, it’s ideal for distribution center, warehouse, manufacturing, transportation and retail environments.',
        p_2: 'With a full-color, graphical display including 9 user-selectable languages, the PM42 is intuitive and easy to use, reducing your workforce training and device support needs. And workers can easily manage their printing jobs from nearby or afar with the printer’s integrated web interface, which enables easy setup, monitoring and configuration through mobile devices including tablets and smartphones.',
        p_3: 'The user-selectable, all-in-one programming language included in every PM42 printer enables it to fit easily into most IT infrastructures, deploying quickly into existing Honeywell or mixed printer environments.',
        img: img_8
    },
    {
        id: 10,
        category: 'printer',
        name: 'PM43 Printer',
        brief: 'The Intermec PM43 a industrial label printers deliver fast, drop-in deployment, advanced connectivity and proven reliability',
        p_1: 'The Intermec PM43 a industrial label printers deliver fast, drop-in deployment, advanced connectivity and proven reliability to maximize uptimeb being the only printers available with WiFi connectivity. Now you can print small barcodes, text and images with pinpoint accuracy.',
        p_2: 'Each PM43, PM43c and PM23c printer has an integrated web interface that allows for easy setup, monitoring and configuration through devices like handheld computers, tablets or smartphones. Integrated device management and diagnostic capabilities, provided through the Honeywell SmartSystems™ Foundation and Wavelink Avalanche®, reduce downtime and simplify deployment.',
        p_3: 'The PM Series printers are coengineered and tested with Honeywell Media Products, ensuring optimized printhead performance when used with genuine Honeywell media.',
        img: img_9
    },
    {
        id: 11,
        category: 'printer',
        name: 'ZD220 Thermal Transfer Printer',
        brief: 'The ZD220 desktop printer gives you reliable operation and basic features at an affordable price—both at the point of purchase and across the entire lifecycle.',
        p_1: 'The ZD220 desktop printer gives you reliable operation and basic features at an affordable price—both at the point of purchase and across the entire lifecycle. Engineered with Zebra quality, it boasts a dual-wall construction for added durability. And, it is ENERGY STAR® qualified to reduce your operational expenses. ',
        p_2: ' Built to last for years, the ZD220 provides an outstanding return on your investment. This 4-inch printer produces labels quickly, at 4” per second, to keep your workflows moving. Increased memory allows you to store more fonts and graphics. A single LED indicator and single button for feed/pause make it easy to operate and identify printer status. Choose from direct thermal or thermal transfer models, and add an optional factory-installed peeler.',
        p_3: 'The ZD220 features Print DNA Basic, a core set of software applications and capabilities to deliver a cost-effective and dependable print experience. The ZD220 is simple to install and begin printing—right out of the box. USB connectivity allows for universal interoperability. Easily replace legacy printers using ZPL, EPL or non-Zebra command languages. And, media changes are expedited with Zebra’s OpenACCESS™ clamshell design. It is ideal for use in transportation and logistics, light manufacturing, retail and healthcare applications to print a variety of tickets, tags, passes, labels and receipts.',
        img: img_10
    },
    {
        id: 12,
        category: 'printer',
        name: 'ZQ600 Series Mobile Printers',
        brief: 'The ZQ600 Series takes the popular QLn platform and makes it even better, adding advanced technology and innovative design that drive productivity',
        p_1: 'The ZQ600 Series takes the popular QLn platform and makes it even better, adding advanced technology and innovative design that drive productivity, ease-of-use and manageability to a new level. Minimise downtime and ensure your printer never sleeps with advanced battery technology, more battery capacity and instant wake-on status.',
        p_2: ' A large colour display with status icons helps take the guesswork out of the equation. Your workers will enjoy the dependable wireless connections with fast 802.11 ac and Bluetooth 4.1. With support for 802.11ac Wi-Fi and its latest security protocols, the ZQ600 Series offers the fastest, most dependable wireless connection inside the four walls. And the industry’s only instant wake-up over Wi-Fi feature delivers instant availability for your workers and maximum power efficiency.',
        img: img_11
    },
    {
        id: 13,
        category: 'label',
        name: 'Black Barcode Resin Ribbons',
        brief: 'DNP TR4085plus Resin Enhanced Wax Thermal Transfer Ribbons-Zebra Industrial Printers-Coated Side Out (CSO).',
        img: img_12
    },
    {
        id: 14,
        category: 'printer',
        name: 'ZT400 Series Industrial Printer',
        brief: 'Drive productivity with the ZT400 Series. Constructed with an all-metal frame and bi-fold door, these printer are durable and allow easy access',
        p_1: 'Drive productivity with the ZT400 Series. Constructed with an all-metal frame and bi-fold door, these printer are durable and allow easy access. The 4.3-inch full-colour touch display with intuitive interface makes them easy to use, and shows colour-coded printer status alerts. Configure your printer your way with advanced technology, along with two open communication slots. These printers keep your critical operations running efficiently today, and give you the flexibility to add features in the future as you need them.',
        p_2: 'Available in three print resolutions and two print widths, the ZT400 Series addresses a broad array of applications. They are easy to integrate and manage with Zebra’s Print DNA suite of applications, utilities and developer tools—all powered by Link-OS®.',
        p_3: 'It’s easy to set up and use the ZT400 with its intuitive icon-based interface. The 4.3-inch full-color touch display allows you to quickly navigate printer settings with the user friendly icon menu system to change languages, printer settings, connectivity options and more.',
        img: img_13
    },
    {
        id: 15,
        category: 'mobile computer',
        name: 'Skorpio X5',
        brief: 'The new Skorpio X5 enables retail, transportation and logistics, manufacturing, and healthcare companies',
        p_1: 'The new Skorpio X5 enables retail, transportation and logistics, manufacturing, and healthcare companies to reach new levels of reliability and flexibility.',
        p_2: 'This ultra-fast, high-performing key-based mobile computer has the largest multi-touch display in the Portable Data Terminal (PDT) market. It delivers everything companies need to ensure the utmost productivity and to stand out in terms of effectiveness and efficiency. The Skorpio X5 features dominant computational CPU power and the latest generation of scan engines, specifically designed and developed by Datalogic, including a unique Mid-Range imager. With this rugged tool, workers can immediately read any barcode, in any condition, whether labels are damaged, scratched, visible or under a plastic wrap.',
        p_3: 'The Skorpio X5 is conceived for increased productivity and reduced error rates in operations, resulting in increased profitability. It delivers unmatched intuitiveness, user experience, stability, and security. The outstanding features of the Skorpio X5 are designed to fully satisfy all customers’ requests.',
        img: img_14
    }
]