import React from 'react';
import './Clients.scss';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import client_1 from '../../assets/asd.png';
import client_2 from '../../assets/courier_guy.png';
import client_3 from '../../assets/dod.png';
import client_4 from '../../assets/expeditors.png';
import client_5 from '../../assets/frigoglass.png';
import client_6 from '../../assets/hyundai.png';
import client_7 from '../../assets/overnight.png';
import client_8 from '../../assets/skf.png';
import client_9 from '../../assets/solutions.png';
import client_10 from '../../assets/toyota.png';
import client_11 from '../../assets/trans.png';

const Clients = () => {
    return (
        <div className='container-fluid py-5 clients'>
            <h2 className='mb-3 text-center'>Our Clients</h2>
            <div className='large-splide'>
                <Splide aria-label="My Favorite Images"
                    options={{
                        type: 'loop',
                        width : '100%',
                        gap   : '2rem',
                        perPage: 5,
                        perMove: 1,
                        autoplay: true,
                    }}
                >
                    <SplideSlide>
                        <img src={client_1} className='img-fluid' alt="asd consulting engineers" width='200' height='200' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src={client_2} className='img-fluid' alt="the courier guy" width='200' height='200'/>
                    </SplideSlide>
                    <SplideSlide>
                        <img src={client_3} className='img-fluid' alt="dod" width='200' height='200'/>
                    </SplideSlide>
                    <SplideSlide>
                        <img src={client_4} className='img-fluid' alt="expeditors" width='200' height='200'/>
                    </SplideSlide>
                    <SplideSlide>
                        <img src={client_5} className='img-fluid' alt="frigo glass" width='200' height='200'/>
                    </SplideSlide>
                    <SplideSlide>
                        <img src={client_6} className='img-fluid' alt="hyundai" width='200' height='200'/>
                    </SplideSlide>
                    <SplideSlide>
                        <img src={client_7} className='img-fluid' alt="overnight logistics" width='200' height='200'/>
                    </SplideSlide>
                    <SplideSlide>
                        <img src={client_8} className='img-fluid' alt="SKF" width='200' height='200'/>
                    </SplideSlide>
                    <SplideSlide>
                        <img src={client_9} className='img-fluid' alt="solutions 4 u" width='200' height='200'/>
                    </SplideSlide>
                    <SplideSlide>
                        <img src={client_10} className='img-fluid' alt="toyota" width='200' height='200'/>
                    </SplideSlide>
                    <SplideSlide>
                        <img src={client_11} className='img-fluid' alt="trans airfreight" width='200' height='200'/>
                    </SplideSlide>
                </Splide>
            </div>
            <div className='medium-splide'>
                <Splide aria-label="My Favorite Images"
                    options={{
                        type: 'loop',
                        width : '100%',
                        gap   : '2rem',
                        perPage: 3,
                        perMove: 1,
                        autoplay: true,
                    }}
                >
                    <SplideSlide>
                        <img src={client_1} className='img-fluid' alt="asd consulting engineers" width='200' height='200' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src={client_2} className='img-fluid' alt="the courier guy" width='200' height='200' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src={client_3} className='img-fluid' alt="dod" width='200' height='200' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src={client_4} className='img-fluid' alt="expeditors" width='200' height='200' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src={client_5} className='img-fluid' alt="frigo glass" width='200' height='200' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src={client_6} className='img-fluid' alt="hyundai" width='200' height='200' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src={client_7} className='img-fluid' alt="overnight logistics" width='200' height='200' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src={client_8} className='img-fluid' alt="SKF" width='200' height='200' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src={client_9} className='img-fluid' alt="solutions 4 u" width='200' height='200' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src={client_10} className='img-fluid' alt="toyota" width='200' height='200' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src={client_11} className='img-fluid' alt="trans airfreight" width='200' height='200' />
                    </SplideSlide>
                </Splide>
            </div>
        </div>
    );
};

export default Clients;