import { React, useState } from 'react';
import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api';
import TopNavbar from '../../components/navbar/TopNavbar';
import Hero from '../../components/hero/Hero';
import Footer from '../../components/footer/Footer';
import './Contact.scss';
import emailjs from '@emailjs/browser';
import { ThreeCircles } from  'react-loader-spinner'
import { Helmet } from 'react-helmet-async';

const center = {
    lat: -26.199535,
    lng: 28.289363
};

const Contact = () => {

    const [ fullName, setFullName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ cell, setCell ] = useState('');
    const [ subject, setSubject ] = useState('');
    const [ message, setMessage ] = useState('');
    const [ msg, setMsg ] = useState(false);
    const [ alrt, setAlrt ] = useState('');
    const [ alrtMsg, setAlrtMsg ] = useState('');
    const [ sending, setSending ] = useState(false);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyAdvVqwM1pWKGTPo8wnqNcoFDx85KM7pLA"
    })

    let templateParams = {
        fullName,
        email,
        cell,
        subject,
        message
    }

    let userParams = {
        fullName,
        email
    };

    const alertProps = (_1, _2, _3, _4) => {
        setSending(_1);
        setMsg(_2);
        setAlrt(_3);
        setAlrtMsg(_4);   

        setFullName('');
        setEmail('');
        setCell('');
        setSubject('');
        setMessage('');

        setTimeout(() => {
            setMsg(false);
        }, 5000)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setSending(true);

        emailjs.send('service_klzykzj', 'template_ej6ucoq', templateParams, 'zDdxRUBwxb-uBCHJY')
            .then(res => {
                emailjs.send('service_klzykzj', 'template_ij3wblg', userParams,'zDdxRUBwxb-uBCHJY')
                    .then(res => {
                        alertProps(false, true, 'success', 'Your contact request was successfully sent!');                     
                    }, err => {
                        alertProps(false, true, 'danger', 'Unable to send request.');
                    })
            }, err => {
                alertProps(false, true, 'danger', 'Unable to send request.');
            })
    }
    
    return (
        <div className='contact-us'>
            <Helmet>
                <title>Contact Us | Balungisi Solutions | sales@balungisisolutions.co.za</title>
                <meta
                    name="description"
                    content="Contact Us. Reach out to get a solution tailored to your business."
                />
                <link rel="canonical" href="/contact" />
                <meta 
                    property="og:title" 
                    content="Contact Us | Balungisi Solutions | sales@balungisisolutions.co.za" 
                />
                <meta 
                    property="og:description" 
                    content="Contact Us. Reach out to get a solution tailored to your business." 
                />
                <meta 
                    property="og:url" 
                    content="https://balungisisolutions.co.za/contact" 
                />
            </Helmet>
            <TopNavbar />
            <Hero page='contact-page' title='Contact Us' subtitle='Reach out to get a solution tailored to your business' />
            <div className="container">
                <div className="row py-5 contact-row">
                    <div className="col-md-5">
                        {isLoaded ? 
                            <GoogleMap
                                center={center}
                                zoom={16}
                                mapContainerClassName='map-container'
                            >
                                <MarkerF 
                                    position={center} 
                                />
                            </GoogleMap> 
                        : <>Loading...</> }
                    </div>
                    <div className="col-md-5">
                        <h2>Get in touch</h2>
                        <h5>Complete the form below</h5>
                        <form onSubmit={(e) => {handleSubmit(e)}}>
                            <div className="mb-2">
                                <label htmlFor="name" className="form-label">Full Name</label>
                                <input 
                                    required
                                    type="text" 
                                    className="form-control" 
                                    id="name" 
                                    value={fullName}
                                    onChange={e => setFullName(e.target.value)}
                                />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="email" className="form-label">Email Address</label>
                                <input 
                                    required
                                    type="email" 
                                    className="form-control" 
                                    id="email" 
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="cell" className="form-label">Cell Number</label>
                                <input 
                                    required
                                    type="number" 
                                    className="form-control" 
                                    id="cell" 
                                    value={cell}
                                    onChange={e => setCell(e.target.value)}
                                />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="subject" className="form-label">Subject</label>
                                <input 
                                    required
                                    type="text" 
                                    className="form-control" 
                                    id="subject" 
                                    value={subject}
                                    onChange={e => setSubject(e.target.value)}
                                />
                            </div>
                            <div className="mb-2">
                                <label htmlFor='message' className='form-label'>Your Message</label>
                                <textarea required value={message} onChange={e => setMessage(e.target.value)} id='message' rows='4' className='form-control'></textarea>
                            </div>
                            {msg && <div className={`alert alert-${alrt} text-center border-${alrt} rounded-0`} role="alert">
                                {alrtMsg}{(alrt === 'danger') && <span> Please contact <a className='text-primary text-decoration-none fw-bold' href="mailto:support@balungisisolutions.co.za">support@balungisisolutions.co.za</a></span>}
                            </div>}
                            {!sending && <button type="submit" className="btn btn-primary rounded-0">Submit</button>}
                            {sending && <button disabled type="submit" className="btn btn-primary rounded-0"><ThreeCircles
                                height="20"
                                width="20"
                                color="#ffffff"
                                ariaLabel="three-circles-rotating"
                                radius="1"
                                wrapperStyle={{ display: 'inline' }}
                                visible={true}
                            /> Sending</button>}
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Contact;