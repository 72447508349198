import React from 'react';
import './Services.scss';
import { services } from '../../data/services';

const Services = () => {
    return (
        <div className="container">
            <div className='services-card row py-5' id='services'>
                <h2 className='heading-1 text-center'>Our Services</h2>
                <h4 className='text-center fw-normal my-3'>Keeping track of inventory and organising shelving requires the right tools. We offer hardware to help meet your needs. Services we offer:</h4>
                {services.map(item => {
                    return (            
                        <div key={item.alt} className='card-container col-md-4'>
                            <div className="card">
                                <div className="card-image">
                                    <img src={item.img} className="card-img-top" alt={item.alt} width={item.width} height={item.height} />
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title">{item.title}</h5>
                                    <ul>
                                        <li>{item.list_1}</li>
                                        <li>{item.list_2}</li>
                                        <li>{item.list_3}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default Services;