export const services = [
    {
        img: 'https://images.unsplash.com/photo-1583911300103-0aaeff286030?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&q=80',
        alt: 'Sale of new hardware',
        title: 'Sale of new hardware',
        list_1: 'Mobile computers',
        list_2: 'RFID components',
        list_3: 'Barcode printers',
        width: 640,
        height: 360
    },
    {
        img: 'https://images.unsplash.com/photo-1581092918056-0c4c3acd3789?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&q=80',
        alt: 'Maintain existing hardware',
        title: 'Maintain existing hardware',
        list_1: 'Highly Skilled Technicians',
        list_2: 'Experience with multiple brands',
        list_3: 'Printers and Scanners',
         width: 640,
        height: 427
    },
    {
        img: 'https://images.unsplash.com/photo-1589792923962-537704632910?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&q=80',
        alt: 'Sale of Labels and tags',
        title: 'Sale of Labels and tags',
        list_1: 'Low Frequency (LF)',
        list_2: 'High Frequency (HF/NFC)',
        list_3: 'Ultra-high Frequency (UHF)',
         width: 640,
        height: 427
    },
    {
        img: 'https://images.unsplash.com/photo-1622127922075-fb1d21fbacbf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&q=80',
        alt: 'Warehouse Consulting',
        title: 'Warehouse Consulting',
        list_1: 'Stock & Inventory Control',
        list_2: 'Supporting technical Units',
        list_3: 'Optimizing service levels',
         width: 640,
        height: 360
    },
    {
        img: 'https://images.pexels.com/photos/163125/board-printed-circuit-board-computer-electronics-163125.jpeg?auto=compress&cs=tinysrgb&w=640&h=427&dpr=1',
        alt: 'RFID Solutions',
        title: 'RFID Solutions',
        list_1: 'RFID Tags & Readers',
        list_2: 'Specific Business Needs',
        list_3: 'Hardware & Software',
        width: 640,
        height: 427
    }
]