import { React, useEffect, useState, useCallback } from 'react';
import Footer from '../../components/footer/Footer';
import TopNavbar from '../../components/navbar/TopNavbar';
import Sidekick from '../../components/sidekick/Sidekick';
import './Products.scss';
import { products } from '../../data/products';
import { Link, useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Products = () => {

    const pathname = useLocation();
    const category = useParams();
    const [ product, setProduct ] = useState([]);
    const [ title, setTitle ] = useState();
    const [ subtitle, setSubtitle ] = useState();

    const renderProducts = useCallback(() => {
        setProduct(products.filter(item => {
            return item.category === category.category
        }))

        setSubtitle('Superior scanners, maintenance, and customer service');

        switch(category.category) {
            case 'printer':
                setTitle('Barcode Printers');
                document.title = 'Barcode Printers | Balungisi Solutions | Honeywell | Datalogic | Zebra';
                break;
            case 'scanner':
                setTitle('Barcode Scanners');
                document.title = 'Barcode Scanners | Balungisi Solutions | Honeywell | Datalogic | Zebra';
                break;
            case 'mobile computer':
                setTitle('Mobile Computers');
                document.title = 'Mobile Computers | Balungisi Solutions | Honeywell | Datalogic | Zebra';
                break;
            case 'label':
                setTitle('Barcode Labels');
                document.title = 'Barcode Labels | Balungisi Solutions | Honeywell | Datalogic | Zebra';
                break;
            default:
                break;
        }

    }, [category.category])

    useEffect(() => {
        window.scrollTo(0, 0);

        renderProducts();
    }, [renderProducts, pathname])

    return (
        <div className='products'>
            <Helmet>
                <link rel="canonical" href={`/products/${category.category}`} />
                <meta 
                    property="og:url" 
                    content={`https://balungisisolutions.co.za/products/${category.category}`}
                />
                <meta
                    name="description"
                    content="Trusted DataLogic, Honeywell and Zebra Business partners, we provide advanced scannning, printer, labelling and mobile computer products and hardware."
                />
                <meta 
                    property="og:description" 
                    content="Trusted DataLogic, Honeywell and Zebra Business partners, we provide advanced scannning, printer, labelling and mobile computer products and hardware." 
                />
                <meta 
                    property='og:title'
                    content={`${title} | Balungisi Solutions | Honeywell | Datalogic | Zebra`}
                />
            </Helmet>
            <TopNavbar />
            <Sidekick title={title} subtitle={subtitle} page={(category.category === 'mobile computer') ? 'mobiles' : category.category} />
            <div className="container">
                <div className="row py-5">
                    {product.map(item => {
                        return (<div key={item.id} className="card-container col-md-4">
                            <div className="card card-border">
                                <div className="card-image">
                                    <img src={item.img} className="card-img-top" alt={item.name} />
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title">{item.name}</h5>
                                    <p>{item.brief}</p>
                                    <hr />
                                    {item.p_1 ? <Link to={`/product/${item.category}/${item.name.replaceAll(" ", "-")}`}><button className='btn btn-primary rounded-0'>LEARN MORE</button></Link> : <></> }
                                </div>
                            </div>
                        </div>)
                    })}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Products;