import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { Container } from 'react-bootstrap';
import './Sidekick.scss';

const Sidekick = ({ title, subtitle, page }) => {
    return (
        <Container fluid className={`sidekick ${page}`}>
            <AnimatePresence>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1.5 }}>
                    <div className="overlay">
                        <h1>{title}</h1>
                        <h3>{subtitle}</h3>
                    </div>
                </motion.div>
            </AnimatePresence>
        </Container>
    );
};

export default Sidekick;