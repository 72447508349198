import React from 'react';
import './TopNavbar.scss';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';  
import { HashLink } from 'react-router-hash-link';
import logo from '../../assets/logo.png';

const TopNavbar = () => {
    return (
        <Navbar collapseOnSelect expand="lg" bg="white">
            <Container>
                <Navbar.Brand className='logo-container'>
                <Link to='/' className='d-flex'>
                    <img src={logo} className='img-fluid' alt='logo' width='333' height='336' />
                    <div className="d-inline-flex fw-bolder logo-text">
                        BALUNGISI<br /> <span>SOLUTIONS</span>
                    </div>
                </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto"></Nav>
                    <Nav className='nav-container'>
                        <Navbar.Text><Link to='/'>Home</Link></Navbar.Text>
                        <Navbar.Text><Link to='/about'>About</Link></Navbar.Text>
                        <NavDropdown className='drop-down' title="Products" id="basic-nav-dropdown">
                            <Navbar.Text className='link-item'><Link to='/products/scanner'>Barcode Scanners</Link></Navbar.Text>
                            <Navbar.Text className='link-item'><Link to='/products/printer'>Barcode Printers</Link></Navbar.Text>
                            <Navbar.Text className='link-item'><Link to='/products/label'>Barcode Labels</Link></Navbar.Text>
                            <Navbar.Text className='link-item'><Link to='/products/mobile computer'>Mobile Computers</Link></Navbar.Text>
                        </NavDropdown>
                        <Navbar.Text><HashLink  to='/#services'>Services</HashLink></Navbar.Text>
                        <Nav>
                            <Link to='/contact'><button className="btn btn-primary rounded-0">Contact Us</button></Link>
                        </Nav>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default TopNavbar;