import { React, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Home.scss';
import TopNavbar from '../../components/navbar/TopNavbar';
import Hero from '../../components/hero/Hero';
import Cta from '../../components/cta/Cta';
import Clients from '../../components/clients/Clients';
import Footer from '../../components/footer/Footer';
import Services from '../../components/services/Services';
import { Helmet } from 'react-helmet-async';

const Home = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className='home'>
            <Helmet>
                <title>Homepage | Balungisi Solutions | Warehouse Technology Solutions</title>
                <meta
                    name="description"
                    content="Balungisi Solutions is an innovator in the field of warehouse solutions and support for all barcoding, scanning and printing needs."
                />
                <link rel="canonical" href="/" />
                <meta 
                    property="og:title" 
                    content="Homepage | Balungisi Solutions | Warehouse Technology Solutions" 
                />
                <meta 
                    property="og:description" 
                    content="Balungisi Solutions is an innovator in the field of warehouse solutions and support for all barcoding, scanning and printing needs." 
                />
                <meta 
                    property="og:url" 
                    content="https://balungisisolutions.co.za/" 
                />
            </Helmet>
            <TopNavbar />
            <Hero page='home-page' title='Welcome to Balungisi Solutions' subtitle='Providing Fast &amp; Efficient Services' />
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6 mt-5 mx-auto'>
                        <h2 className='heading-1'>Point-to-point Warehouse Technology Solutions</h2>
                        <h2 className='py-2'>Outstanding Customer Satisfaction</h2>
                        <p>
                            At <span className='text-primary'>Balungisi Solutions</span>, Our goal is to minimise any downtime our clients may experience due to unforeseen circumstances. Our Directors have over <span className='text-primary'>10 years of experience</span> between them and are able to provide solutions to many issues in warehouse supply chain management and RFID solutions.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <img src='https://cdn.shopify.com/s/files/1/2012/8987/products/CK65_grande.jpg?v=1656488961' alt='CK65 Handheld Scanner' className='img-fluid'width='600' height='600' />
                    </div>
                </div>
            </div> 
            <Services />   
            <Cta />
            <Clients />
            <Footer />
        </div>
    );
};

export default Home;