import React from 'react';
import { Link } from 'react-router-dom';
import './Cta.scss';

const Cta = () => {
    return (
        <div className='container-fluid bg-primary text-white py-5 cta'>
            <h2 className='heading-1'>Get in touch</h2>
            <p className='text-center'>We are a full-service solutions company, specialising in Warehouse technology.</p>
            <Link to='/contact'><button className='btn btn-lg btn-light text-primary rounded-0 fw-bold'>Contact Us</button></Link>
        </div>
    );
};

export default Cta;